import base from '../base'; // 导入接口域名列表
import axios from '../../http'; // 导入http中创建的axios实例
import { siginHeaders } from "@request/api";
import md5 from "js-md5";

const resource = {
  //资源概览-统计数据
  requestStatisticsResource() {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    const params = { verify: verify };
    return axios.get(`${base.dev}/api/company/statistics/resource`, { params });
  },
  //资源概览-存储用量趋势
  requestStatisticsStorage(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    params['verify'] = verify;
    return axios.get(`${base.dev}/api/company/statistics/storage`, { params });
  },
  //资源概览-流量趋势
  requestStatisticsRate(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    params['verify'] = verify;
    return axios.get(`${base.dev}/api/company/statistics/rate`, { params });
  }
}
export default resource