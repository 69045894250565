/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios';
import router from '../router/index';
import store from '../store/index';
import Vue from 'vue';
import { Loading } from 'element-ui';

// 定义请求次数
let requestCount = 0;
let load;
let timer;
let overTimer = null;

// 客户端请求前显示Loading
function showLoading() {
  if (requestCount === 0) {
    store.commit('updateRequestLoadingStatus', true);
  }
  requestCount++;

  if (overTimer) clearTimeout(overTimer);
  overTimer = setTimeout(() => {
    store.commit('updateRequestLoadingStatus', false);

    requestCount = 0;
    if (timer) clearTimeout(timer);
    if (overTimer) clearTimeout(overTimer);
  }, 120000);
}

function tryHideLoading() {
  requestCount--;
  timer = setTimeout(() => {
    if (requestCount <= 0) {
      store.commit('updateRequestLoadingStatus', false);
      requestCount = 0;
      if (timer) clearTimeout(timer);
      if (overTimer) clearTimeout(overTimer);
    }
  });
}

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  // router.replace({
  //   path: '/login',
  //   query: {
  //     redirect: router.currentRoute.fullPath,
  //   },
  // });
  window.location.href = '/v20/#/login';
};
const JumpLoginPage = (res) => {
  if (res) {
    Vue.prototype.$message.error(res.data.msg);
  }
  tryHideLoading();

  localStorage.removeItem('userInfo');
  store.commit('removeToken');
  store.commit('clearStorage');
  toLogin();
};
/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401:
      toLogin();
      break;
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      // tip('登录过期，请重新登录');
      store.commit('removeToken');
      store.commit('clearStorage');
      // store.commit('loginSuccess', null)
      setTimeout(() => {
        toLogin();
      }, 1000);
      break;
    // 404请求不存在
    case 404:
      // tip('请求的资源不存在');
      break;
    default:
      Vue.prototype.$message.warning('攻城狮正在解决问题,请稍后刷新');
      console.log(other);
  }
};

// 创建axios实例
var instance = axios.create({ timeout: 1000 * 12 });
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/json';

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  (config) => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    const { url } = config;
    const token = store.state.user.token;
    token && (config.headers.Authorization = token);
    let urlArr = url.split('/');
    let lastPath = urlArr[urlArr.length - 1];
    if (
      lastPath !== 'notice' &&
      lastPath !== 'qrcode' &&
      lastPath !== 'status' &&
      lastPath !== 'sign' &&
      lastPath !== 'ispay' &&
      lastPath !== 'scancode' &&
      !(lastPath === 'list' && urlArr[urlArr.length - 2] === 'comment')
    ) {
      showLoading();
    }
    return config;
  },
  (error) => Promise.error(error),
);

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  (res) => {
    const { url } = res.config;
    const urlListArr = url.split('/');
    if (res.status === 200 && res.data.code === 1000) {
      if (
        urlListArr[urlListArr.length - 1] === 'notice' ||
        urlListArr[urlListArr.length - 1] === 'loginScene' ||
        urlListArr[urlListArr.length - 1] === 'status' ||
        urlListArr[urlListArr.length - 1] === 'sign' ||
        urlListArr[urlListArr.length - 1] === 'ispay' ||
        urlListArr[urlListArr.length - 1] === 'weChatCode' ||
        (urlListArr[urlListArr.length - 1] === 'list' &&
          urlListArr[urlListArr.length - 2] === 'comment')
      ) {
        return Promise.resolve(res);
      } else {
        tryHideLoading();
        return Promise.resolve(res);
      }
    } else if (res.status === 200 && res.data.code === 1003) {
      if (
        urlListArr[urlListArr.length - 2] === 'invite' ||
        urlListArr[urlListArr.length - 2] === 'share' ||
        urlListArr[urlListArr.length - 2] === 'tezign'
      ) {
        tryHideLoading();
        return Promise.resolve(res);
      } else {
        // 请先登录的拦截处理
        Vue.prototype.$message.error(res.data.msg);
        tryHideLoading();
        store.commit('removeToken');
        store.commit('clearStorage');
        toLogin();
        return Promise.reject(res);
      }
    } else if (res.status === 200 && res.data.code === 3017) {
      tryHideLoading();
      Vue.prototype.$Check('创建失败，项目数已达到当前会员等级上限', () => {
        router.push({
          name: 'personal',
        });
      });
      return Promise.reject(res);
    } else if (res.status === 200 && res.data.code === 3035) {
      tryHideLoading();
      return Promise.reject(res);
    } else if (res.status === 200 && res.data.code === 3037) {
      tryHideLoading();
      return Promise.reject(res);
    } else if (res.status === 200 && res.data.code === 3047) {
      tryHideLoading();
      return Promise.reject(res);
    } else if (res.status === 200 && res.data.code === 3055) {
      tryHideLoading();
      return Promise.reject(res);
    } else if (res.status === 200 && res.data.code === 3056) {
      tryHideLoading();
      return Promise.reject(res);
    } else if (res.status === 200 && (res.data.code === 4008 || res.data.code === 4009)) {
      return Promise.reject(res);
    } else if (res.status === 200 && res.data.code === 3057) {
      tryHideLoading();
      return Promise.reject(res);
    } else if (res.status === 200 && res.data.code === 3900) {
      tryHideLoading();
      return Promise.reject(res);
    } else if (res.status === 200 && res.data.code === 3015) {
      tryHideLoading();
      return Promise.reject(res);
    } else if (
      res.status === 200 &&
      (res.data.code === 5015 || res.data.code === 8005 || res.data.code === 2111)
    ) {
      //企业停用，到期， 账户停用
      JumpLoginPage(res);
      return Promise.reject(res);
    } else if (res.status === 200 && (res.data.code === 8003 || res.data.code === 8004)) {
      tryHideLoading();
      return Promise.resolve(res);
    } else {
      Vue.prototype.$message.error(res.data.msg);
      tryHideLoading();
      return Promise.reject(res);
    }

    // res.status === 200 && res.data.code === 1000 ? Promise.resolve(res) : Promise.reject(res)
  },
  // 请求失败
  (error) => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message);
      tryHideLoading();
      return Promise.reject(response);
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      if (!window.navigator.onLine) {
        console.log('断网');
        tryHideLoading();
        store.commit('changeNetwork', false);
      } else {
        tryHideLoading();

        return Promise.reject(error);
      }
    }
  },
);

export default instance;
