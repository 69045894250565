import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);
// cv以下代码解决路由地址重复的报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: '/',
    redirect: { name: 'Home' },
    component: () => import(/* webpackChunkName: "homeIndex" */ '../views/home/index.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('../views/home/Home.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/project/projectList',
        name: 'projectList',
        component: () => import('../views/project/ProjectList.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/project/shootList',
        name: 'shootList',
        component: () => import('../views/project/ShootList.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/organization/membersDepartments',
        name: 'MembersDepartments',
        component: () => import('../views/organization/membersDepartments/MembersDepartments.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/organization/externalContacts',
        name: 'ExternalContacts',
        component: () => import('../views/organization/externalContacts/ExternalContacts.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/organization/visitor',
        name: 'visitor',
        component: () => import('../views/organization/visitor/visitor.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/resource/Resource',
        name: 'resource',
        component: () => import('../views/resource/Resource.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/resource/efficiency',
        name: 'Efficiency',
        component: () => import('../views/resource/Efficiency.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/setUp/information',
        name: 'Information',
        component: () => import('../views/setUp/Information.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/setUp/brand',
        name: 'Brand',
        component: () => import('../views/setUp/Brand.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/setUp/jurisdiction',
        name: 'Jurisdiction',
        component: () => import('../views/setUp/jurisdiction/Jurisdiction.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/setUp/journal',
        name: 'Journal',
        component: () => import('../views/setUp/Journal.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/expense/flowList',
        name: 'flowList',
        component: () => import('../views/expense/FlowList.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/expense/billList',
        name: 'billList',
        component: () => import('../views/expense/BillList.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/expense/charge',
        name: 'charge',
        component: () => import('../views/expense/Charge.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/importPage',
        name: 'importPage',
        component: () => import('../views/batchImport/ImportPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/importSuccess',
        name: 'importSuccess',
        component: () => import('../views/batchImport/ImportSuccess.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/importError',
        name: 'importError',
        component: () => import('../views/batchImport/ImportError.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/invite/project/:code',
    name: 'inviteProject',
    component: () => import('../views/invite/InviteProject.vue'),
  },
  {
    path: '/invite/shoot/:code',
    name: 'inviteShoot',
    component: () => import('../views/invite/InviteShoot.vue'),
  },
  {
    path: '/loginReview',
    name: 'loginReview',
    component: () => import('../views/loginReview/LoginReview.vue'),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to, from, 'to, from');
  const { userInfo, token } = store.state.user;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token) {
      const topPath = store.getters['getCurrentUserTopMenuUrl'];
      if (from.path === '/' && topPath != to.path) {
        next(topPath);
      } else {
        next();
      }
    } else {
      window.location.href = '/v20/#/login';
      next(false);
    }
  } else {
    if (to.path === '/login' && process.env.NODE_ENV === 'production') {
      window.location.href = '/v20/#/login';
      next(false);
    } else {
      next();
    }
  }
});

// // 截取分享邀请链接
// router.beforeResolve((to, from, next) => {
//   const result = parseUrl(to.fullPath);
//   if (result.matchUrl[1] === 'invite' && result.matchUrl.length > 2 && result.urlArr.length > 1) {
//     if (result.matchUrl[2] === 'project') {
//       next({
//         name: 'inviteProject',
//         params: {
//           code: `${result.param}`,
//         },
//       });
//     } else if (result.matchUrl[2] === 'shoot') {
//       next({
//         name: 'inviteShoot',
//         params: {
//           code: `${result.param}`,
//         },
//       });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });
// function parseUrl(url) {
//   const urlArr = url.split('%20');
//   const matchUrl = urlArr[0].split('/');
//   const index = urlArr[0].lastIndexOf('/');
//   const param = urlArr[0].substr(index + 1);
//   return {
//     matchUrl,
//     param,
//     urlArr,
//   };
// }

export default router;
