import base from '../base'; // 导入接口域名列表
import axios from '../../http'; // 导入http中创建的axios实例
import { siginHeaders } from "@request/api"
import md5 from "js-md5";
import store from '@store/index';

const invite = {
	// 获取链接信息
	getInviteInfo(params) {
        const headers = siginHeaders();
        let code=params.code.trim()
		const verify=md5(headers.appSerectToken+code);
		params['verify'] = verify;
		params.code=code
		return axios.get(`${base.dev}/api/v1/invite/detail`, {params});
	},
	getJoinProgect(params){
		const headers = siginHeaders();
		const verify = md5(headers.appSerectToken+params.code);
		params['verify'] = verify;
		params['platform'] = 1;
		return axios.post(`${base.dev}/api/company/join/project`,params).then(res=>{
			if(!store.state.user.token){
				store.commit("setUserInfo", res.data.data);
			}
			return Promise.resolve(res);
		}).catch(err=>{
			return Promise.reject(err);
		});
	},
	//拍摄邀请链接详情接口
	requestShootLinkInfo(params){
		const headers = siginHeaders();
		const verify = md5(headers.appSerectToken + params.code);
		params['verify'] = verify;
		return axios.get(`${base.dev}/api/v1/shoot/link`, {params});
	},
	//拍摄邀请链接加入项目接口
	requestShootProjectJoin(params){
		const headers = siginHeaders();
		const verify = md5(headers.appSerectToken + params.code);
		params['verify'] = verify;
		params['platform'] = 1;
		return axios.post(`${base.dev}/api/company/join/shoot`, params).then(res=>{
			if(!store.state.user.token){
				store.commit("setUserInfo", res.data.data);
			}
			return Promise.resolve(res);
		}).catch(err=>{
			return Promise.reject(err);
		})
	},
}
export default invite