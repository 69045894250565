/**
 *
 * 访客
 *
 */

import base from '../base'; // 导入接口域名列表
import axios from '../../http'; // 导入http中创建的axios实例

import { siginHeaders } from '@request/api';
import md5 from 'js-md5';
import store from '@store/index';
export default {
  /**
   *
   * @param {*} params  {
   * type string = visitor - 访客
   * }
   * @returns
   */
  getVisitorList(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    params['type'] = 'visitor';
    params['verify'] = verify;
    return axios.get(`${base.dev}/api/company/visitor/list`, { params });
  },
  /**
   * 编辑访客信息
   * @param {*} params
   * @returns
   */
  editVisitorInfo(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.name + params.phone + params.user_id);
    params['verify'] = verify;
    params['type'] = 'visitor';

    return axios
      .post(`${base.dev}/api/company/visitor/edit`, params)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  /**
   * 修改访客身份
   * @param {*} params
   * position	是	string	职位
   * department_id	是	int	部门ID
   * user_id	是	int	用户id
   * type	否	string	类型: external - 外部联系人 (默认成员)
   * verify	是	string	验证参数
   */
  changeVisitorIdentity(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.user_id + params.department_id);
    params['verify'] = verify;

    return axios
      .post(`${base.dev}/api/company/visitor/change`, params)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  /**
   * 修改访客状态
   * @param {*} params
   * status	是	int	状态: 1-正常 0-停用
   * user_id	是	int	用户id
   * verify	是	string	验证参数
   * @returns
   */
  changeVisitorStatus(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.status + params.user_id);
    params['verify'] = verify;

    return axios
      .put(`${base.dev}/api/company/visitor/member/status`, params)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
