export default {
  state: {
    oauthToken: localStorage.getItem('oauthToken') || '',
  },
  mutations: {
    setOauthToken(state, token) {
      (state.oauthToken = token), localStorage.setItem('oauthToken', token);
    },
    setTest(state, token) {
      console.log(token,'test-token')
    },
  },
  actions: {},
  getters: {},
};
