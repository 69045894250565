/**
 *
 * @param {Number} size 字节大小
 * 返回文件大小格式
 */
export function getFileSize(size, isDelZero = false) {
  if (!size) return '';
  var num = 1024.0; //byte
  if (size < num) return size + 'B';
  if (size < Math.pow(num, 2))
    return isDelZero ? parseFloat((size / num).toFixed(2)) + 'KB' : (size / num).toFixed(2) + 'KB'; //kb
  if (size < Math.pow(num, 3))
    return isDelZero
      ? parseFloat((size / Math.pow(num, 2)).toFixed(2)) + 'MB'
      : (size / Math.pow(num, 2)).toFixed(2) + 'MB'; //M
  if (size < Math.pow(num, 4))
    return isDelZero
      ? parseFloat((size / Math.pow(num, 3)).toFixed(2)) + 'GB'
      : (size / Math.pow(num, 3)).toFixed(2) + 'GB'; //G
  return isDelZero
    ? parseFloat((size / Math.pow(num, 4)).toFixed(2)) + 'TB'
    : (size / Math.pow(num, 4)).toFixed(2) + 'TB'; //T
}

/**
 *
 * @param {Number} size 字节大小
 * 返回文件大小单位
 */
export function getFileSizeByNumber(size) {
  if (!size) return '';
  var num = 1024.0; //byte
  if (size < num) return size;
  if (size < Math.pow(num, 2)) return parseFloat((size / num).toFixed(2)); //kb
  if (size < Math.pow(num, 3)) return parseFloat((size / Math.pow(num, 2)).toFixed(2)); //M
  if (size < Math.pow(num, 4)) return parseFloat((size / Math.pow(num, 3)).toFixed(2)); //G
  return parseFloat((size / Math.pow(num, 4)).toFixed(2)); //T
}

/**
 *
 * @param {Number} size 字节大小
 * 返回文件大小单位
 */
export function getFileSizeByUnit(size) {
  if (!size) return '';
  var num = 1024.0; //byte
  if (size < num) return 'B';
  if (size < Math.pow(num, 2)) return 'KB';
  if (size < Math.pow(num, 3)) return 'MB'; //M
  if (size < Math.pow(num, 4)) return 'GB'; //G
  return 'TB'; //T
}

/**
 *
 * @param {Number} size 字节大小
 * 返回
 */
export function getEchartScaleUnitBySize(size) {
  if (!size) return '';
  var num = 1024.0; //byte
  if (size < num) return 100;
  if (size < Math.pow(num, 2)) return 10000;
  if (size < Math.pow(num, 3)) return 1000000; //M
  if (size < Math.pow(num, 4)) return 1000000000; //G
  return 1000000000000; //T
}
