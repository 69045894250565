/**
 * 全局常量
 */
export default {

        //  身份: 1企业管理人 2超级管理员 3 普通管理员 4普通成员 5外部联系人
        identity:{
            enterpriseAdmin:1,
            superAdmin:2,
            admin:3,
            member:4,
            externalMember:5,
        }

}