/**
 * login模块接口列表
 */

import base from '../base'; // 导入接口域名列表
import axios from '../../http'; // 导入http中创建的axios实例

import { siginHeaders } from '@request/api';
import md5 from 'js-md5';
import store from '@store/index';
import Vue from 'vue';

const u = navigator.userAgent;
let platform;
if (u.indexOf('iPhone') > -1) {
  platform = 5;
} else if (u.indexOf('iPad') > -1) {
  platform = 6;
} else if (u.indexOf('Android') > -1) {
  if (u.indexOf('Mobile') > -1) {
    platform = 8;
  } else {
    platform = 7;
  }
} else {
  platform = 1;
}

const login = {
  tokenLogin(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.token);
    params['verify'] = verify;
    params['platform'] = platform;
    return axios
      .post(`${base.dev}/api/company/ent/token`, params)
      .then(({data}) => {
        
        const { code } = data
        if(code == 1000){
          store.commit('setToken', data.data.token);
        }
        return Promise.resolve(data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  // 登录账号接口
  requestLogin(params) {
    const headers = siginHeaders();
    const verify = md5(
      headers.appSerectToken + params.phone + params.email + params.code + params.password,
    );

    params['verify'] = verify;
    params['platform'] = platform;
    return axios
      .post(`${base.dev}/api/company/login`, params)
      .then((res) => {
        store.commit('setUserInfo', res.data.data);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  //请求验证码接口
  requestCode(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.phone + params.email);
    params['verify'] = verify;
    return axios.post(`${base.dev}/api/v1/user/sendCode`, params);
  },
  //重置密码接口
  requestResetPass(params) {
    const headers = siginHeaders();
    const verify = md5(
      headers.appSerectToken +
        params.phone +
        params.email +
        params.code +
        params.password +
        params.passwordConfirm,
    );
    params['verify'] = verify;
    return axios
      .post(`${base.dev}/api/v1/user/resetPass`, params)
      .then((res) => {
        Vue.prototype.$message.success('重置密码成功!');
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  //退出登录接口
  requestLogout() {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    const params = { verify: verify };
    return axios
      .post(`${base.dev}/api/v1/user/logout`, params)
      .then((res) => {
        store.commit('removeToken');
        store.commit('clearStorage');
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  //小程序登录二维码接口
  requestWeChatCode() {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    const params = { verify: verify };
    return axios.get(`${base.dev}/api/company/login/qrcode`, { params });
  },
  //扫码登陆轮训的接口
  requestWeChatSceneLogin(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.scene_token);
    params['verify'] = verify;
    return axios.post(`${base.dev}/api/company/login/scancode`, params);
  },
};

export default login;
