<template>
  <div id="app">
    <router-view />
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import Loading from '@components/Common/Loading/Loading.vue';
import { mapState } from "vuex";
export default {
  name: "APP",
  computed: {
    ...mapState({
      isLoading: (state) => state.isShowRequestLoading,
      isLogin: (state) => state.user.token,
      userInfo: (state) => state.user.userInfo,
    }),
  },
  components:{
    Loading
  },
  created() {
    if (this.isLogin) {
      const curHref = window.location.href;
      if (
        curHref.indexOf("/invite/project/") > 0 ||
        curHref.indexOf("/invite/shoot/") > 0
      ) {
        
      } else {
        if (this.userInfo.identity > this.$constant.identity.admin && process.env.NODE_ENV === 'production') {
          location.pathname = "/v20/";
          return;
        }
      }
    }
    if (
      window.navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      // 拍摄/帮助中心 - 不跳转到移动端
      if (
        window.location.href.indexOf("help") > 0 ||
        window.location.href.indexOf("doc-preview") > 0 ||
        window.location.href.indexOf("share/shareLiveScreen") > 0 ||
        window.location.href.indexOf("share/shareShoot") > 0 ||
        window.location.href.indexOf("share/shoot") > 0 ||
        window.location.href.indexOf("tezign") > 0
      ) {
        return;
      }
      let share = window.location.href.split("/share/")[1];
      if (share !== undefined) {
        window.location.href = "/v20m/#/src/views/share/share?code=" + share;
        return;
      }
      let invite = window.location.href.split("/invite/project/")[1];
      if (invite !== undefined) {
        window.location.href =
          "/v20m/#/src/views/invite/InviteProject?code=" + invite;
        return;
      }
      let inviteShoot = window.location.href.split("/invite/shoot/")[1];
      if (inviteShoot !== undefined) {
        window.location.href =
          "/v20m/#/src/views/invite/InviteShoot?code=" + inviteShoot;
        return;
      }
      let help = window.location.href.indexOf("help");
      if (help !== -1) {
        return;
      }
      // 拍摄不跳转到移动端
      let shoot = window.location.href.split("/shoot/")[1];
      if (shoot) return;
      window.location.href = "/v20m/";
      return;
    }
    this.$store.dispatch("requestCompanyBasicInfo");
  },
  mounted() {
    document.title = this.$store.state.user.companyBasicInfo.company_name;
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  min-width: 1024px;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.big-btn {
  margin-top: 50px;
  width: 446px;
  height: 54px;
  background: #3468fe;
  border-radius: 6px;
  color: white;
  flex-shrink: 0;
}
input::-webkit-input-placeholder {
  color: #868c97;
}
input:-moz-placeholder {
  color: #868c97;
}
input::-moz-placeholder {
  color: #868c97;
}
input::-ms-input-placeholder {
  color: #868c97;
}
@media screen and (max-width: 640px) {
  #app {
    min-width: 100%;
  }
}
@media screen and (max-width: 1366px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1919px) {
  html {
    font-size: 13px;
  }
}

@media screen and (min-width: 1920px) {
  html {
    font-size: 14px;
  }
}
</style>
