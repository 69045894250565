import base from '../base'; // 导入接口域名列表
import axios from '../../http'; // 导入http中创建的axios实例
import { siginHeaders } from "@request/api"
import md5 from "js-md5";
import store from "@store/index";

const account = {
	//获取企业基础信息
	requestCompanyBasicinfo() {
		const headers = siginHeaders();
		const verify = md5(headers.appSerectToken);
		const params = { verify: verify };
		axios.get(`${base.dev}/api/company/basicinfo`, { params }).then(res => {
			store.commit("setCompanyBasicInfo", res.data.data);
		}).catch(err => {
			console.log(err);
		});
	},
  //获取账号信息接口
  requestAccountInfo() {
    const headers = siginHeaders()
    const verify = md5(headers.appSerectToken)
    const params = { verify: verify }
    return axios.get(`${base.dev}/api/v1/user/accountInfo`, { params }).then((res) => {
			store.commit('updateAccount', res.data.data);
			return Promise.resolve(res);
		}).catch((error) => {
			return Promise.reject(error);
		})
  },
}
export default account