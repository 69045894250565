/**
 * api接口的统一出口
 */
import md5 from 'js-md5'
import base from './base'
import axios from '../http'
// 登录、注册相关模块接口
import login from './login/login'
import invite from './invite/invite'
import account from './account/account'
// 企业设置相关模块
import enterprise from './enterprise/enterprise'
// 组织管理相关模块
import organization from './organization/organization'
import visitor from './organization/visitor'
import project from './project/project'
import resource from './resource/resource'
import upload from './upload/upload'
import bill from './bill/bill'
import charge from './charge/charge'

// 导出接口
export default {
  login,
  invite,
  account,
  enterprise,
  project,
  organization,
  visitor,
  resource,
  upload,
  bill,
  charge
}
export function siginHeaders () {
  const timestamp = new Date().getTime()
  const appSerectToken = md5(base.appSerect + timestamp)
  axios.defaults.headers.appSerectToken = appSerectToken
  axios.defaults.headers.timestamp = timestamp
  return {
    appSerectToken: appSerectToken,
    timestamp: timestamp
  }
}

const SHARE_TOKEN_PREFIX = 'share_token' // 分享链接 Token 前缀

/**
 * 设置分享链接 Token
 */
export function setShareToken ({ code, password }) {
  const shareToken = md5([password, base.shareSecret].join(''))
  localStorage.setItem(`${SHARE_TOKEN_PREFIX}_${code}`, shareToken)
}

/**
 * 获取分享链接 Token
 */
export function getShareToken ({ code }) {
  return localStorage.getItem(`${SHARE_TOKEN_PREFIX}_${code}`)
}
