/**
 * 充值模块接口列表
 */

import base from '../base'; // 导入接口域名列表
import axios from '../../http'; // 导入http中创建的axios实例
import { siginHeaders } from "@request/api";
import md5 from "js-md5";
import store from "@store/index";

const charge = {
    //创建订单接口
    requestWechatPay(params) {
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken + params.price);
        params['verify'] = verify;
        return axios.post(`${base.dev}/api/company/finance/wechat/pay`, params);
    },
    //线下收款账户
    requestAccountInfo() {
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken);
        const params = { verify: verify };
        axios.get(`${base.dev}/api/company/accountinfo`, { params }).then(res=>{
            store.commit("setChargeAccountInfo", res.data.data);
        }).catch(err=>{
            console.log(err);
        });
    },
    //查询充值是否支付
    requestRechargeIsPay(params) {
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken + params.order_sn);
        params['verify'] = verify;
        return axios.get(`${base.dev}/api/company/finance/recharge/ispay`, { params });
    },
}

export default charge;