const base = {
  localhost: 'http://localhost:8080/',
  prod: 'https://ent-d.yueliu.cloud',
  // dev: 'https://dev1.vtutor.com.cn', // 开发环境
  dev: 'https://test-ent.vtutor.com.cn', //企业版开发环境
  // dev: "https://v20d.vtutor.com.cn",     //备用-开发
  appSerect: 'qEuQUObml3nuwf7y',
  shareSecret: 'IcausNY152NHtxFx', // 分享验签密钥
};
const host = window.location.host;
if (
  !(host.indexOf('vtutor.com.cn') > -1) &&
  !(host.indexOf('localhost') > -1) &&
  !(host.indexOf('127.0.0.1') > -1)
) {
  base.dev = base.prod;
}
// console.log(window.location.host.indexOf('uxinyue.com')>-1,base);
export default base;
