import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import oauth from './modules/oauth';
import state from './state';
import mutations from './mutations';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: user,
    oauth: oauth,
  },
  state,
  mutations,
});
