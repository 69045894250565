import request from '@request/api/index';
export default {
  state: {
    token: localStorage.getItem('token') || '',
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {
      uId: -1,
      uName: '',
      uImg: '',
      email: '',
      phone: '',
      has_password: 1,
      sex: 3,
      sexStr: '',
      age: 10,
      introduce: '',
      is_wechat: 0,
      is_invoice: 0,
      topTip: 0,
      vip: 0,
      vip_failure_time: '0',
      is_first_home: 0,
      is_first_page: 0,
      is_complete_info: 0,
      role_type: null,
      trade_id: null,
      occupation_id: null,
      identity: 1, //  身份: 1企业管理人 2超级管理员 3 普通管理员 4普通成员 5外部联系人
      is_first_create_project: 0,
      position: '', //职位
      external_company: '', //公司
      department_name: '', //部门
      media_list: [],
      menu: [],
      media_auth: {
        look_permission: false, //查看
        download_permission: false, //下载
        share_permission: false, //分享
        copy_permission: false, //复制
        delete_permission: false, //删除
        tag_permission: false, //标签管理
        up_permission: false, //上传编辑
        move_permission: false, //移动
      },
      version: 'v20',
    },
    fileUrl: '',
    errorData: [], //导入错误信息
    companyBasicInfo: JSON.parse(localStorage.getItem('companyBasicInfo')) || {
      light_logo: '',
      dark_logo: '',
      login_background: '',
      login_slogan: '',
      company_name: '',
    },

    financeInfo: JSON.parse(localStorage.getItem('financeInfo')) || {
      balance: '0.00',
      balance_waring: 0,
      balance_waring_price: '0.00',
    },
    chargeAccountInfo: { account_name: '', account_number: '', account_bank: '' },
  },
  mutations: {
    previewLoginBackground(state, info) {
      state.fileUrl = info;
      localStorage.setItem('fileUrl', state.fileUrl);
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    removeToken(state) {
      state.token = '';
      localStorage.removeItem('token');
    },
    clearStorage() {
      const lastSysNoticeTime = localStorage.getItem('lastSysNoticeTime');
      const userAccount = localStorage.getItem('userAccount') || '';
      const isFirstDownload = localStorage.getItem('isFirstDownload');
      const isMediaRenamePop = localStorage.getItem('mediaRightRenamePopStatus');
      const isMediaTicketPop = localStorage.getItem('mediaRightTicketPopStatus');
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('lastSysNoticeTime', lastSysNoticeTime);
      localStorage.setItem('isFirstDownload', isFirstDownload);
      if (isMediaRenamePop) {
        localStorage.setItem('mediaRightRenamePopStatus', isMediaRenamePop);
      }
      if (isMediaRenamePop) {
        localStorage.setItem('mediaRightTicketPopStatus', isMediaTicketPop);
      }
      if (userAccount !== null) {
        // FIXME: userAccount 为 null， setItem 赋值会让 null 对象变为字符串 'null'
        localStorage.setItem('userAccount', userAccount);
      }
    },
    setUserInfo(state, info) {
      state.userInfo.uId = info.uId === null ? '' : info.uId;
      state.userInfo.uImg = info.uImg === null ? '' : info.uImg;
      state.userInfo.uName = info.uName === null ? '' : info.uName;
      state.userInfo.email = info.email === null ? '' : info.email;
      state.userInfo.topTip = info.topTip;
      state.userInfo.vip = info.vip;
      state.userInfo.is_first_home = info.is_first_home;
      state.userInfo.is_first_page = info.is_first_page;
      state.userInfo.is_complete_info = info.is_complete_info;
      state.userInfo.vip_failure_time = info.vip_failure_time;
      state.userInfo.role_type = info.role_type;
      state.userInfo.trade_id = info.trade_id;
      state.userInfo.occupation_id = info.occupation_id;
      state.userInfo.is_invoice = info.is_invoice === null ? '0' : info.is_invoice;
      state.userInfo.identity = info.identity;
      state.userInfo.is_first_create_project = info.is_first_create_project;
      state.userInfo.menu = info.menu;
      state.token = info.token === null ? '' : info.token;
      state.userInfo.has_password = info.is_password;
      state.userInfo.media_list = info.media;
      //媒资权限处理
      const media_auth = info.media[0];
      state.userInfo.media_auth = {
        look_permission: Boolean(media_auth.look_permission), //查看
        download_permission: Boolean(media_auth.download_permission), //下载
        share_permission: Boolean(media_auth.share_permission), //分享
        copy_permission: Boolean(media_auth.copy_permission), //复制
        delete_permission: Boolean(media_auth.delete_permission), //删除
        tag_permission: Boolean(media_auth.tag_permission), //标签管理
        up_permission: Boolean(media_auth.up_permission), //上传编辑
        move_permission: Boolean(media_auth.move_permission), //移动
      };

      state.userInfo.version = info.version === null ? 'v20' : info.version;
      localStorage.setItem('token', info.token);
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
    },
    updateAccount(state, info) {
      state.userInfo.phone = info.phone === null ? '' : info.phone;
      state.userInfo.email = info.email === null ? '' : info.email;
      state.userInfo.uName = info.nickname === null ? '' : info.nickname;
      state.userInfo.uImg = info.avatar === null ? '' : info.avatar;
      state.userInfo.sex = info.sex === null ? 3 : info.sex;
      state.userInfo.age = info.age === null ? 0 : info.age;
      state.userInfo.introduce = info.introduce === null ? '' : info.introduce;
      state.userInfo.vip = info.vip;
      state.userInfo.topTip = info.topTip;
      state.userInfo.vip_failure_time = info.vip_failure_time;
      state.userInfo.trade_id = info.trade_id;
      state.userInfo.occupation_id = info.occupation_id === null ? '' : info.occupation_id;
      state.userInfo.role_type = info.role_type;
      // state.userInfo.is_complete_info = info.is_complete_info
      state.userInfo.sexStr = info.sex === 1 ? '男' : info.sex === 2 ? '女' : '未知';
      state.userInfo.is_wechat = info.is_wechat === null ? 0 : info.is_wechat;
      state.userInfo.has_password = info.has_password === null ? 0 : info.has_password;
      state.userInfo.position = info.position;
      state.userInfo.external_company = info.external_company;
      state.userInfo.department_name = info.department_name;
      if (info.identity) {
        state.userInfo.identity = info.identity;
      }
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
    },

    setCompanyBasicInfo(state, info) {
      state.companyBasicInfo.light_logo = info.light_logo;
      state.companyBasicInfo.dark_logo = info.dark_logo;
      state.companyBasicInfo.login_background = info.login_background;
      state.companyBasicInfo.login_slogan = info.login_slogan;
      if (info.company_name) {
        state.companyBasicInfo.company_name = info.company_name;
      }
      localStorage.setItem('companyBasicInfo', JSON.stringify(state.companyBasicInfo));
    },
    setFinanceInfo(state, info) {
      state.financeInfo = info;
      localStorage.setItem('financeInfo', JSON.stringify(state.financeInfo));
    },
    getErrorData(state, info) {
      state.errorData = info;
      localStorage.setItem('errorData', JSON.stringify(state.errorData));
    },
    setBalanceWaringAndPrice(state, info) {
      const { balance_waring, balance_waring_price } = info;
      const { balance } = state.financeInfo;
      const data = { balance, balance_waring, balance_waring_price };
      state.financeInfo = data;
      localStorage.setItem('financeInfo', JSON.stringify(state.financeInfo));
    },
    setChargeAccountInfo(state, info) {
      state.chargeAccountInfo = info;
    },
  },
  //处理异步
  actions: {
    async requestCompanyBasicInfo() {
      await request.account.requestCompanyBasicinfo();
    },
    async requestUserAccountInfo() {
      await request.account.requestAccountInfo();
    },
  },
  //提取对state的公共操作
  getters: {
    getUserInfo(state) {
      return state.userInfo;
    },
    getCurrentUserMenuList(state) {
      //如果是这2个角色就把所有菜单设置给他
      //这里需要跟随后台数据更新而更新，手动！！！！
      if (state.userInfo.identity == 1 || state.userInfo.identity == 2)
        return [
          'home',
          'statistics',
          'project',
          'shoot',
          'department',
          'member',
          'visitor',
          'finance',
          'flow',
          'info',
          'brand',
          'auth',
          'log',
          'efficiency',
          'visitor',
        ];
      else return state.userInfo.menu;
    },
    getCurrentUserTopMenuUrl(state) {
      if (state.userInfo.identity == 1 || state.userInfo.identity == 2) return '/home';

      //这里需要跟随后台数据更新而更新，手动！！！！
      switch (state.userInfo.menu && state.userInfo.menu[0]) {
        case 'home':
          return '/home';
        case 'statistics':
          return '/resource/Resource';
        case 'project':
          return '/project/projectList';
        case 'shoot':
          return '/project/shootList';
        case 'department':
          return '/organization/membersDepartments';
        case 'member':
          return '/organization/externalContacts';
        case 'visitor':
          return '/organization/visitor';
        case 'finance':
          return '/expense/billList';
        case 'flow':
          return '/expense/flowList';
        case 'info':
          return '/setUp/information';
        case 'brand':
          return '/setUp/brand';
        case 'auth':
          return '/setUp/jurisdiction';
        case 'log':
          return '/setUp/journal';
        default:
          return '/home';
      }
    },
  },
};
