/**
 * home页和企业信息模块接口列表
 */
import base from "../base"; // 导入接口域名列表
import axios from "../../http"; // 导入http中创建的axios实例

import { siginHeaders } from "@request/api";
import md5 from "js-md5";
import store from "@store/index";
import Vue from "vue";

const enterprise={
    // 企业信息
    requestInfo(params){
        const headers = siginHeaders();
		const verify = md5(headers.appSerectToken);
        params['verify'] = verify;
	     return	axios.get(`${base.dev}/api/company/info`,{params}).then(res=>{
            return Promise.resolve(res);
        }).catch(err=>{
            console.log(err);
        });
    },
    //操作日志
    requestJournal(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken);
        params['verify'] = verify;
        return	axios.get(`${base.dev}/api/company/log`,{params}).then(res=>{
            return Promise.resolve(res);
        }).catch(err=>{
            console.log(err);
        });
      },
    //   管理员权限
    requestjurisdiction(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken);
        params['verify'] = verify;
        return	axios.get(`${base.dev}/api/company/auth`,{params}).then(res=>{
            return Promise.resolve(res);
        }).catch(err=>{
            console.log(err);
        });
    },
    // 添加编辑
    requestAddEdit(params){
		const headers = siginHeaders();
		const verify = md5(headers.appSerectToken + params.user_id+params.type);
		params['verify'] = verify;
	return axios.post(`${base.dev}/api/company/auth`, params).then(res=>{
            return Promise.resolve(res);
        }).catch(err=>{
            console.log(err);
        });;  
    },
    //权限列表
    requestjurisdictList(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken);
        params['verify'] = verify;
        return	axios.get(`${base.dev}/api/company/auth/choose`,{params}).then(res=>{
           return Promise.resolve(res);
       }).catch(err=>{
           console.log(err);
       });
    },
    // 删除权限
    requestDeljurist(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken+params.user_id);
        params['verify'] = verify;
        return axios.delete(`${base.dev}/api/company/auth`, { data: params });
    },
    // 转让账号
    requestTransferAccountNumber(params){
			const headers = siginHeaders();
			const verify = md5(headers.appSerectToken + params.old_user_id+params.user_id+params.code);
			params['verify'] = verify;
			return axios.post(`${base.dev}/api/company/auth/transfer`, params);
    },
    //   首页数据
    requestHome(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken);
        params['verify'] = verify;
        return	axios.get(`${base.dev}/api/company/home/info`,{params})
    },
    //xian
    requestHomeLine(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken);
        params['verify'] = verify;
        return	axios.get(`${base.dev}/api/company/home/usage`,{params})
    },
    //企业设置--获取品牌信息
    requestBrandInfo(){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken);
        const params = { verify: verify };
        axios.get(`${base.dev}/api/company/brand`,{params}).then(res=>{
            store.commit("setCompanyBasicInfo", res.data.data);
        }).catch(err=>{
            console.log(err);
        });
    },
    //企业设置--保存品牌信息
    requestBrandSet(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken + 
            params.light_logo + 
            params.dark_logo + 
            params.login_background + 
            params.login_slogan);
        params['verify'] = verify;
        axios.post(`${base.dev}/api/company/brand`, params).then(res=>{
            store.commit("setCompanyBasicInfo", res.data.data);
            Vue.prototype.$message.success("修改品牌信息成功!");
        }).catch(err=>{
            console.log(err);
        });
    },
    //人效管理
    requestEnterprise(params){
        const headers = siginHeaders();
		const verify = md5(headers.appSerectToken);
        params['verify'] = verify;
	     return	axios.get(`${base.dev}/api/company/efficiency/user`,{params})
    },
        //人效管理审核图
        requesToExamine(params){
            const headers = siginHeaders();
            const verify = md5(headers.appSerectToken+
                params.project_id+
                params.user_id+
                params.day
                );
            params['verify'] = verify;
            return axios.get(`${base.dev}/api/company/efficiency/user/data`,{params})
        }
}


export default enterprise;