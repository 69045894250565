import base from '../base'; // 导入接口域名列表
import axios from '../../http'; // 导入http中创建的axios实例
import { siginHeaders } from "@request/api";
import md5 from "js-md5";
import { getFileSize } from "@utils/getFileSize.js";
import moment from "dayjs";

const project = {
  //项目列表页初始化数据
  requestProjectInit() {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    const params = { verify: verify };
    return axios.get(`${base.dev}/api/company/project/init`, { params });
  },
  //下拉筛选--所有者
  requestProjectUsers() {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    const params = { verify: verify };
    return axios.get(`${base.dev}/api/company/common/project/users`, { params });
  },
  //项目列表
  requestProject() {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    const params = { verify: verify };
    return axios.get(`${base.dev}/api/company/project`, { params }).then(res => {
      const { list } = res.data.data;
      const arr = [];
      list.forEach(item => {
        const { file_count, name, user_name, users, user_id, project_number } = item;
        let storage_count = getFileSize(item.storage_count);
        let created_at = moment(item.created_at * 1000).format("YYYY-MM-DD HH:mm");
        let updated_at = moment(item.updated_at * 1000).format("YYYY-MM-DD HH:mm");
        const newData = { created_at, updated_at, storage_count, file_count };
        newData.obj = {name, user_name, users, user_id, project_number};
        arr.push(newData);
      });
      return Promise.resolve(arr);
    }).catch(err => {
      return Promise.reject(err);
    });
  },
  //项目详情数据
  requestProjectDetail(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.project_id);
    params['verify'] = verify;
    return axios.get(`${base.dev}/api/company/project/detail`, { params });
  },
  //编辑项目
  requestProjectEditor(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.project_id);
    params['verify'] = verify;
    return axios.post(`${base.dev}/api/company/project`, params);
  },
  //删除项目
  requestProjectDelete(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.project_id);
    params['verify'] = verify;
    return axios.delete(`${base.dev}/api/company/project`, { data: params });
  },
  //拍摄列表总计数据
  requestShootInit() {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    const params = { verify: verify };
    return axios.get(`${base.dev}/api/company/shoot/init`, { params });
  },
  //拍摄列表
  requestShoot() {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    const params = { verify: verify };
    return axios.get(`${base.dev}/api/company/shoot`, { params }).then(res => {
      const { list } = res.data.data;
      const arr = [];
      list.forEach(item => {
        const { files, shoot_name, user_name, users, user_id, shoot_number} = item;
        let sizes = parseFloat(item.sizes) == 0 ? '0B' : getFileSize(parseFloat(item.sizes));
        let created_at = moment(item.created_at * 1000).format("YYYY-MM-DD HH:mm");
        let updated_at = moment(item.updated_at * 1000).format("YYYY-MM-DD HH:mm");
        const newData = { created_at, updated_at, sizes, files };
        newData.obj = {shoot_name, user_name, users, user_id, shoot_number};
        arr.push(newData);
      });
      return Promise.resolve(arr);
    }).catch(err => {
      return Promise.reject(err);
    });
  },
  //删除拍摄
  requestShootDelete(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.shoot_id);
    params['verify'] = verify;
    return axios.delete(`${base.dev}/api/company/shoot`, { data: params });
  },
  //编辑拍摄
  requestShootEditor(params) {
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.shoot_id);
    params['verify'] = verify;
    return axios.post(`${base.dev}/api/company/shoot`, params);
  },
}
export default project