export default {
  setStopCountDownSate(state, isStop) {
    console.log('被设置');
    state.isStopCountDown = isStop;
  },
  // 更新全局 Loading 状态
  updateRequestLoadingStatus(state, status) {
    state.isShowRequestLoading = status;
  },

};
