/**
 * home页和企业信息模块接口列表
 */
import base from "../base"; // 导入接口域名列表
import axios from "../../http"; // 导入http中创建的axios实例

import { siginHeaders } from "@request/api";
import md5 from "js-md5";
import store from "@store/index";
// import Vue from "vue";

const organization={
    //成员列表
    requestMember(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken);
        params['verify'] = verify;
        return	axios.get(`${base.dev}/api/company/department/member/list`,{params})
    },
    //添加成员
    requestAddMember(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken+params.name+params.phone+params.department_id);
        params['verify'] = verify;
        return	axios.post(`${base.dev}/api/company/department/member/add`,params).then(res=>{
            return Promise.resolve(res);
        }).catch(err=>{
            console.log(err);
        });
    },
    // 编辑成员
    requestEditMember(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken+params.name+params.phone+params.department_id+params.user_id);
        params['verify'] = verify;
		params['verify'] = verify;
		return axios.put(`${base.dev}/api/company/department/member/edit`,
			params
		);
    },
    //删除成员
        requestDelMember(params){
            const headers = siginHeaders();
            const verify = md5(headers.appSerectToken+params.user_id);
            params['verify'] = verify;
            return axios.delete(`${base.dev}/api/company/department/member/delete`, { data: params });
        },
    //编辑成员状态
     requestStatus(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken+params.status+params.user_id);
        params['verify'] = verify;
		params['verify'] = verify;
		return axios.put(`${base.dev}/api/company/department/member/status`,
			params
		);
     },
   //搜索成员列表
   repuestSeaarchMember(params){
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    params['verify'] = verify;
    return	axios.get(`${base.dev}/api/company/department/member/search/list`,{params}).then(res=>{
        return Promise.resolve(res);
    }).catch(err=>{
        console.log(err);
    });
   },
    //部门搜索列表  //  左侧部门搜索
    requestSearchDepartment(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken);
        params['verify'] = verify;
        return	axios.get(`${base.dev}/api/company/common/department/search/list`,{params}).then(res=>{
            return Promise.resolve(res);
        }).catch(err=>{
            console.log(err);
        });
    },
    // 部门列表
    requestDepartment(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken);
        params['verify'] = verify;
        return	axios.get(`${base.dev}/api/company/department/list`,{params}).then(res=>{
            return Promise.resolve(res);
        }).catch(err=>{
            console.log(err);
        });
    },
    // 添加部门
    requestAddDepartment(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken+params.name);
        params['verify'] = verify;
        return	axios.post(`${base.dev}/api/company/department/add`,params).then(res=>{
            return Promise.resolve(res);
        }).catch(err=>{
            console.log(err);
        });
    },
    //编辑部门
    requestEditDepartment(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken+params.name+params.id);
        params['verify'] = verify;
        return	axios.put(`${base.dev}/api/company/department/edit`,params).then(res=>{
            return Promise.resolve(res);
        }).catch(err=>{
            console.log(err);
        });
    },
    // 删除部门
    requestDelDepartment(params){
        const headers = siginHeaders();
        const verify = md5(headers.appSerectToken+params.id);
        params['verify'] = verify;
        return axios.delete(`${base.dev}/api/company/department/delete`, { data: params });
    },
// 外部联系人列表
   getExternalContactsList(params){
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    params['verify'] = verify;
    return	axios.get(`${base.dev}/api/company/member/external/list`,{params}).then(res=>{
        return Promise.resolve(res);
    }).catch(err=>{
        console.log(err);
    });
   },
//添加外部联系人
 requestAddExternalContacts(params){
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken+params.name+params.phone);
    params['verify'] = verify;
    return	axios.post(`${base.dev}/api/company/member/external/add`,params).then(res=>{
        return Promise.resolve(res);
    }).catch(err=>{
        console.log(err);
    });
   },
//编辑外部联系人
requestEditExternalContacts(params){
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken+params.name+params.phone+params.user_id);
    params['verify'] = verify;
    return	axios.put(`${base.dev}/api/company/member/external/edit`,params).then(res=>{
        return Promise.resolve(res);
    }).catch(err=>{
        console.log(err);
    });
},
//删除外部联系人
requestDelExternalContacts(params){
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken+params.user_id);
    params['verify'] = verify;
    return axios.delete(`${base.dev}/api/company/department/member/delete`, { data: params });
},
//外部联系人转为成员
requestMembership(params){
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken+params.user_id+params.department_id);
    params['verify'] = verify;
    return	axios.put(`${base.dev}/api/company/member/external/transform`,params).then(res=>{
        return Promise.resolve(res);
    }).catch(err=>{
        console.log(err);
    });
},
// 批量人员导入
requestBatchImport(params){
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    params['verify'] = verify;
    console.log('request',params)
    const data = new FormData();
        // const fileUps = file.file;
    data.append('excelfile', params.excelfile);
    data.append('verify', params.verify);
    return axios.post(`${base.dev}/api/company/department/member/import`, data,{
        headers: {
            'Content-Type': 'multipart/form-data'
        },
      }).then(res=>{
        return Promise.resolve(res);
      }).catch(err=>{
      return  err
    });
},
// 下载错误报告
requestErrorReport(params){
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    params['verify'] = verify;
    console.log('params',params)
    var url= 'https://ent-dev.vtutor.com.cn/api/company/department/member/report?params={}&verify='+verify;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);        // 也可以使用POST方式，根据接口
    xhr.responseType = "blob";    // 返回类型blob
    xhr.setRequestHeader("appSerectToken", headers.appSerectToken);
    xhr.setRequestHeader("timestamp", headers.timestamp);
    xhr.setRequestHeader("Authorization",store.state.user.token);
    // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
    xhr.onload = function () {  
        // 请求完成
            // 返回200
            var blob = this.response;
            var reader = new FileReader();
            reader.readAsDataURL(blob);    // 转换为base64，可以直接放入a表情href
            reader.onload = function (e) {
                // 转换完成，创建一个a标签用于下载
                var a = document.createElement('a');
                a.download = '错误报告.xls';
                console.log("e",e.target)
                a.href = e.target.result;
                //$("body").append(a);    // 修复firefox中无法触发click
                a.click();
                // $(a).remove();
            }
    };
    // 发送ajax请求
    xhr.send()
},
//批量外部联系人导入
reuqestExternalBatchImport(params){
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    params['verify'] = verify;
    console.log('request',params)
    const data = new FormData();
    data.append('excelfile', params.excelfile);
    data.append('verify', params.verify);
    return axios.post(`${base.dev}/api/company/member/external/import`, data,{
        headers: {
            'Content-Type': 'multipart/form-data'
        },
      }).then(res=>{
        return Promise.resolve(res);
      }).catch(err=>{
      return  err
    });
},
// 外部联系人下载错误报告
requestExternalErrorReport(params){
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    params['verify'] = verify;
    console.log('params',params)
    var url= 'https://ent-dev.vtutor.com.cn/api/company/member/external/report?params={}&verify='+verify;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);        // 也可以使用POST方式，根据接口
    xhr.responseType = "blob";    // 返回类型blob
    xhr.setRequestHeader("appSerectToken", headers.appSerectToken);
    xhr.setRequestHeader("timestamp", headers.timestamp);
    xhr.setRequestHeader("Authorization",store.state.user.token);
    // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
    xhr.onload = function () {  
        // 请求完成
            // 返回200
            var blob = this.response;
            var reader = new FileReader();
            reader.readAsDataURL(blob);    // 转换为base64，可以直接放入a表情href
            reader.onload = function (e) {
                // 转换完成，创建一个a标签用于下载
                var a = document.createElement('a');
                a.download = '错误报告.xls';
                console.log("e",e.target)
                a.href = e.target.result;
                //$("body").append(a);    // 修复firefox中无法触发click
                a.click();
                // $(a).remove();
            }
    };
    // 发送ajax请求
    xhr.send()
},

}

export default organization;