<template>
  <div class="loading-spinner-wrapper flex-row flex-all-center">
    <img src="@assets/imgs/loading.svg"
      class="loading">
  </div>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style scoped>
.loading-spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100001;
  background: transparent;
}
.loading {
  width: 54px;
}
</style>
