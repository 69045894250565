import base from '../base'; // 导入接口域名列表
import axios from '../../http'; // 导入http中创建的axios实例
import { siginHeaders } from "@request/api";
import md5 from "js-md5";

const upload = {
  //上传图片获取签名接口
  requestUploadSignature(params){
    const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.fileName);
    params['verify'] = verify;
    return axios.post(`${base.dev}/api/v1/upload/avatarSignature`, params);
  },
}
export default upload