import base from '../base'; // 导入接口域名列表
import axios from '../../http'; // 导入http中创建的axios实例
import { siginHeaders } from "@request/api"
import md5 from "js-md5";
import moment from "dayjs";
import {getFileSize} from '@utils/getFileSize.js'
import store from "@store/index";
import Vue from "vue";

const bill = {
	//收支明细
	requestFlowList(params) {
		const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.start_time + params.end_time);
    params['verify'] = verify;
    return axios.get(`${base.dev}/api/company/flow/list`, { params }).then(res => {
      const arr = [];
      res.data.data.forEach(item => {
        const { trade_number, company_bill, balance, type, pay_type} = item;
        const account_number = company_bill ? company_bill.account_number : '-';
        const account_period = company_bill ? company_bill.account_period : '-';
        const typeStr = item.type === 1 ? '<span style="color:#FF9900">支出</span>' : '<span style="color:#19BE6B">收入</span>';
        const pay_typeStr = item.pay_type === 1 ? '消费' : '充值';
        const pay_channel = item.pay_channel === 1 ? '用户余额' : item.pay_channel === 2 ? '微信' : '线下';
        const price = item.pay_type === 1 ? `<span style="color:#FF9900">- ¥${item.price}</span>`
           : `<span style="color:#19BE6B">+ ¥${item.price}</span>`;
        const remark = item.remark ? item.remark : '-';
        let created_at = moment(item.created_at * 1000).format("YYYY-MM-DD HH:mm:ss");
        const newData = { trade_number, type, typeStr, pay_type, pay_typeStr, pay_channel, price, remark, company_bill, account_number, account_period, created_at, balance };
        arr.push(newData);
      });
      return Promise.resolve(arr);
    }).catch(err => {
      return Promise.reject(err);
    });
  },
  //账单列表
	requestBillList(params) {
		const headers = siginHeaders();
    const verify = md5(headers.appSerectToken + params.date);
    params['verify'] = verify;
    return axios.get(`${base.dev}/api/company/finance/bill/list`, { params }).then(res => {
      const arr = [];
      res.data.data.forEach(item => {
        const { account_period, account_number, product_type, billing_model, begin_time, end_time, pay_status} = item;
        const bonusStr = item.product_type === 1 ? '存储空间' : '流量';
        const billingModelStr = item.billing_model === 1 ? '后付费' : '后付费';
        let unitPrice = item.unit_price + '元/GB';
        unitPrice += item.product_type === 1 ? '/天' : '';
        const storage = getFileSize(item.storage);
        const amountPayable = '¥' + item.amount_payable;
        const payStatus = item.pay_status === 1 ? '未结清' : '已支付';
        const arrears = '¥' + item.arrears;
        const newData = { account_period, account_number, product_type, billing_model, 
          begin_time, end_time, pay_status, bonusStr, billingModelStr, unitPrice, storage, 
          amountPayable, payStatus, arrears };
        arr.push(newData);
      });
      return Promise.resolve(arr);
    }).catch(err => {
      return Promise.reject(err);
    });
  },
  //账单管理基础信息
	requestFinanceInfo() {
		const headers = siginHeaders();
    const verify = md5(headers.appSerectToken);
    const params = {verify};
    axios.get(`${base.dev}/api/company/finance/info`, { params }).then(res => {
      store.commit("setFinanceInfo", res.data.data);
    }).catch(err => {
      console.log(err);
    });
  },
  //开启预警
  //修改预警阈值操作
  requestFinanceSet(params){
    const headers = siginHeaders();
    const resultModel = {code:0,data:''}
    params['verify'] = md5(headers.appSerectToken);
    return axios.post(`${base.dev}/api/company/finance/set`, params).then(res=>{
      store.commit("setBalanceWaringAndPrice", params);
      return  res.data;
    }).catch(err=>{
      console.log(err);
      return resultModel
    });
}
}
export default bill