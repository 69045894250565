import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import iView from 'iview'
import 'iview/dist/styles/iview.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import echarts from 'echarts'
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
import '@assets/index.scss';
import Constant  from '@utils/constant'
Vue.prototype.$echarts = echarts
Vue.prototype.$constant = Constant
Vue.use(iView)
Vue.use(ElementUI);
Vue.use(Avue);
Vue.config.productionTip = false
new Vue({
  router,
  store,
  echarts,
  render: h => h(App)
}).$mount('#app')
export default router
